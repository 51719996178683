import useBusinessStore from '../stores/business';
import useCategoryStore from '../stores/category';
import useClientStore from '../stores/client';
import useContractStore from '../stores/contract';
import usePortfolioStore from '../stores/portfolio';
import useProductStore from '../stores/product';
import useProviderStore from '../stores/provider';
import useReferenceStore from '../stores/reference';

const resetStores = () => {
  useBusinessStore().$reset();
  useCategoryStore().$reset();
  useClientStore().$reset();
  useContractStore().$reset();
  usePortfolioStore().$reset();
  useProductStore().$reset();
  useProviderStore().$reset();
  useReferenceStore().$reset();
};

export default resetStores;
