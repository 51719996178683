interface CategoryData {
  id?: number;
  name?: string;
  featured?: boolean;
}

export default class Category {
  id: number;

  name: string;

  featured: boolean;

  constructor(params?: CategoryData) {
    Object.assign(this, params);
  }

  static fromJSON(data: CategoryData) {
    return new Category(data);
  }
}
