import Provider from './provider';

interface ProductData {
  id?: number;
  name?: string;
  provider?: Provider;
}

export default class Product {
  id: number;

  name: string;

  provider: Provider;

  constructor(params?: ProductData) {
    Object.assign(this, params);
  }

  static fromJSON(data: ProductData) {
    return new Product({
      ...data,
      provider: data.provider && new Provider(data.provider),
    });
  }
}
