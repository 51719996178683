import { defineStore } from 'pinia';

import Client from '../models/client';
import clientService from '../services/client';
import useSnackbar from './snackbar';

const useClientStore = defineStore('client', {
  state: () => ({
    items: null as Client[],
    loading: false,
  }),
  actions: {
    async create(client: Client) {
      this.loading = true;

      try {
        const newClient = await clientService.create(client);

        this.items?.push(newClient);

        return newClient;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async load() {
      if (!this.items) {
        await this.read();
      }
    },
    async read() {
      this.items = [];

      this.loading = true;

      try {
        this.items = await clientService.read({
          join: ['business', 'address'],
          fields: ['client.*', 'business.*', 'address.*'],
          paginate: false,
        });
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async readOne(id: number) {
      const item = this.items?.find((client) => client.id === id);

      if (item) {
        return item;
      }

      this.loading = true;

      try {
        return await clientService.readOne(id);
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async update(client: Client) {
      this.loading = true;

      const index = this.items?.findIndex(({ id }) => id === client.id);
      const isInList = this.items && index !== -1;
      const oldClient = this.items[index];

      if (isInList) {
        this.items.splice(index, 1, client);
      }

      try {
        await clientService.update(client);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        if (isInList) {
          this.items.splice(index, 1, oldClient);
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
    async remove(client: Client) {
      const index = this.items.findIndex(({ id }) => id === client.id);
      const oldClient = this.items[index];

      if (index === -1) {
        return false;
      }

      this.items.splice(index, 1);

      try {
        await clientService.remove(client);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        this.items.splice(index, 0, oldClient);

        return false;
      }
    },
  },
});

export default useClientStore;
