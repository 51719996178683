import { APIDataResult } from '../models/api-data-result';
import Contract from '../models/contract';
import SharePortfolio from '../models/share-portfolio';
import { SharedPortfolio } from '../models/shared-portfolio';
import api from './api';
import apiRoutes from './api-routes';

const findContracts = async (query: any) => {
  const { data } = await api.get<APIDataResult<Contract[]>>(apiRoutes.portfolio, { params: query });

  return data.data?.map((value) => Contract.fromJSON(value));
};

const share = async (sharePortfolio: SharePortfolio) => {
  const { data } = await api.post<APIDataResult<SharedPortfolio>>(`${apiRoutes.portfolio}/share`, sharePortfolio);

  return data.data && SharedPortfolio.fromJSON(data.data);
};

const read = async (query?: any) => {
  const { data } = await api.get<APIDataResult<SharedPortfolio[]>>(apiRoutes.sharedPortfolio, {
    params: query,
  });

  return data.data?.map(SharedPortfolio.fromJSON);
};

const readOne = async (id: string) => {
  const { data } = await api.get<APIDataResult<SharedPortfolio>>(`${apiRoutes.sharedPortfolio}/${id}`);

  return data.data && SharedPortfolio.fromJSON(data.data);
};

const readOnePublic = async (id: string) => {
  const { data } = await api.get<APIDataResult<SharedPortfolio>>(`public${apiRoutes.sharedPortfolio}/${id}`);

  return data.data && SharedPortfolio.fromJSON(data.data);
};

const portfolioService = {
  findContracts,
  share,
  read,
  readOne,
  readOnePublic,
};

export default portfolioService;
