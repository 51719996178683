import { defineStore } from 'pinia';

import Reference from '../models/reference';
import referenceService from '../services/reference';
import useSnackbar from './snackbar';

const useReferenceStore = defineStore('reference', {
  state: () => ({
    items: null as Reference[],
    loading: false,
  }),
  actions: {
    async create(reference: Reference) {
      this.loading = true;

      try {
        const newReference = await referenceService.create(reference);

        this.items?.push(newReference);

        return newReference;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async load() {
      if (!this.items) {
        await this.read();
      }
    },
    async read() {
      this.items = [];

      this.loading = true;

      try {
        this.items = await referenceService.read({
          join: ['categories', 'contract', 'contract.client', 'contract.product'],
          fields: ['reference.*', 'categories.*', 'contract.*', 'client.name', 'product.name'],
          paginate: false,
        });
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async readOne(id: number) {
      this.loading = true;

      try {
        return await referenceService.readOne(id);
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async update(reference: Reference) {
      this.loading = true;

      const index = this.items?.findIndex(({ id }) => id === reference.id);
      const isInList = this.items && index !== -1;
      const oldReference = this.items[index];

      if (isInList) {
        this.items.splice(index, 1, reference);
      }

      try {
        await referenceService.update(reference);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        if (isInList) {
          this.items.splice(index, 1, oldReference);
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
    async remove(reference: Reference) {
      const index = this.items.findIndex(({ id }) => id === reference.id);
      const oldReference = this.items[index];

      if (index === -1) {
        return false;
      }

      this.items.splice(index, 1);

      try {
        await referenceService.remove(reference);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        this.items.splice(index, 0, oldReference);

        return false;
      }
    },
  },
});

export default useReferenceStore;
