export enum Roles {
  Admin = 'f3adf03c16334f3394caee640c263087',
  Seller = '1274154b57474e32a9aac78491795102',
}

export enum NamedRoles {
  Admin = 'admin',
  Seller = 'seller',
}

export const matchRoles = (allowedRoles: Roles[], userRoles: Roles[]) =>
  userRoles?.length && allowedRoles.some((role) => userRoles.includes(role));
