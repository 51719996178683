import { APIDataResult } from '../models/api-data-result';
import Contract from '../models/contract';
import api from './api';
import apiRoutes from './api-routes';

const read = async (query?: any) => {
  const { data } = await api.get<APIDataResult<Contract[]>>(apiRoutes.contract, {
    params: query,
  });

  return data.data?.map((value) => Contract.fromJSON(value));
};

const create = async (contract: Contract) => {
  const { data } = await api.post<APIDataResult<Contract>>(apiRoutes.contract, {
    ...contract,
    client: undefined,
    clientId: contract.client.id,
    product: undefined,
    productId: contract.product.id,
  });

  return data.data && Contract.fromJSON({ product: contract.product, client: contract.client, ...data.data });
};

const update = async (contract: Contract) => {
  await api.put(`${apiRoutes.contract}/${contract.id}`, contract);
};

const contractService = {
  read,
  create,
  update,
};

export default contractService;
