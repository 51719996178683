import { extend, localize } from 'vee-validate';
import ptBR from 'vee-validate/dist/locale/pt_BR.json';
import { email, max, min, required } from 'vee-validate/dist/rules';

import { maxDate, minDate } from '../config/validations/date';
import document from '../config/validations/document';

extend('required', required);
extend('min', min);
extend('max', max);
extend('email', email);
extend('document', document);
extend('minDate', minDate);
extend('maxDate', maxDate);

localize('pt_BR', ptBR);
