import { APIDataResult } from '../models/api-data-result';
import Reference from '../models/reference';
import api from './api';
import apiRoutes from './api-routes';

const read = async (query?: any) => {
  const { data } = await api.get<APIDataResult<Reference[]>>(apiRoutes.reference, { params: query });

  return data.data.map((value) => Reference.fromJSON(value));
};

const readOne = async (id: number) => {
  const { data } = await api.get(`${apiRoutes.reference}/${id}`);

  return data.data && Reference.fromJSON(data.data);
};

const create = async (reference: Reference) => {
  const { data } = await api.post<APIDataResult<Reference>>(apiRoutes.reference, {
    ...reference,
    contract: undefined,
    contractId: reference.contract.id,
    categories: reference.categories.map((category) => category.id),
  });

  return (
    data.data &&
    Reference.fromJSON({
      ...data.data,
      contract: reference.contract,
      categories: reference.categories,
    })
  );
};

const update = async (reference: Reference) => {
  await api.put(`${apiRoutes.reference}/${reference.id}`, {
    ...reference,
    contract: undefined,
    contractId: reference.contract.id,
    phone: reference.phone?.replace(/\D/g, ''),
    categories: reference.categories.map((category) => category.id),
  });
};

const remove = async (reference: Reference) => {
  await api.delete(`${apiRoutes.reference}/${reference.id}`);
};

const referenceService = {
  read,
  readOne,
  create,
  update,
  remove,
};

export default referenceService;
