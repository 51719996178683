import { APIDataResult } from '../models/api-data-result';
import Client from '../models/client';
import api from './api';
import apiRoutes from './api-routes';

const read = async (query?: any) => {
  const { data } = await api.get<APIDataResult<Client[]>>(apiRoutes.client, { params: query });

  return data.data.map((value) => Client.fromJSON(value));
};

const readOne = async (id: number) => {
  const { data } = await api.get<APIDataResult<Client>>(`${apiRoutes.client}/${id}`);

  return data.data && Client.fromJSON(data.data);
};

const create = async (client: Client) => {
  const { data } = await api.post<APIDataResult<Client>>(apiRoutes.client, {
    ...client,
    business: undefined,
    businessId: client.business.id,
  });

  return data.data && Client.fromJSON(data.data);
};

const update = async (client: Client) => {
  await api.put(`${apiRoutes.client}/${client.id}`, { ...client, source: undefined });
};

const remove = async (client: Client) => {
  await api.delete(`${apiRoutes.client}/${client.id}`);
};

const clientService = {
  read,
  readOne,
  create,
  update,
  remove,
};

export default clientService;
