import { defineStore } from 'pinia';

import Account from '../models/account';
import AuthState from '../models/auth-state';
import BaseCompany from '../models/base-company';
import authService from '../services/auth';
import resetStores from '../utils/reset-stores';
import useSnackbar from './snackbar';

const useAuthStore = defineStore('auth', {
  state: () =>
    ({
      user: null,
      companies: null,
      selected: null,
    } as AuthState),
  getters: {
    hasMoreThanOneOption: (state) => state.companies.length > 1,
  },
  actions: {
    async login(account: Account) {
      try {
        const { user, companies } = await authService.login(account);

        this.user = user;
        this.companies = companies;

        if (!this.hasMoreThanOneOption) {
          this.select(companies[0]);
        }

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return false;
      }
    },
    logout() {
      resetStores();
      this.$reset();
    },
    select(company: BaseCompany) {
      this.selected = company;
      this.user.roles = company.roles;
      resetStores();
    },
  },
  persist: true,
});

export default useAuthStore;
