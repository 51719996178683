const cnpjValidator = {
  isValid(cnpj: string) {
    const document = cnpj.replace(/[^\d]/g, '');

    if (document.length !== 14) {
      return false;
    }

    if (/^(\d)\1+$/.test(document)) {
      return false;
    }

    let numbers = document.slice(0, 12);

    numbers += this.verifierDigit(numbers);
    numbers += this.verifierDigit(numbers);

    return numbers.slice(-2) === document.slice(-2);
  },

  verifierDigit: (digits: string) => {
    let index = 2;
    const numbers = digits.split('').map((digit) => parseInt(digit, 10));
    const reverse = numbers.reverse();
    const sum = reverse.reduce((buffer, digit) => {
      const result = buffer + digit * index;

      index = index === 9 ? 2 : index + 1;

      return result;
    }, 0);

    const mod = sum % 11;

    return mod < 2 ? 0 : 11 - mod;
  },
};

const cpfValidator = {
  isValid(cpf: string) {
    const document = cpf.replace(/[^\d]/g, '');

    if (document.length !== 11) {
      return false;
    }

    if (/^(\d)\1+$/.test(document)) {
      return false;
    }

    let numbers = document.slice(0, 9);

    numbers += this.verifierDigit(numbers);
    numbers += this.verifierDigit(numbers);

    return numbers.slice(-2) === document.slice(-2);
  },

  verifierDigit(digits: string) {
    const numbers = digits.split('').map((digit) => parseInt(digit, 10));
    const modulus = numbers.length + 1;
    const multiplied = numbers.map((digit, index) => digit * (modulus - index));
    const mod = multiplied.reduce((buffer, digit) => buffer + digit) % 11;

    return mod < 2 ? 0 : 11 - mod;
  },
};

const documentValidator = {
  message: 'O campo {_field_} deve ser válido',
  validate: (value: string) => {
    const document = value.replace(/[^\d]/g, '');

    if (document.length !== 11 && document.length !== 14) {
      return false;
    }

    return document.length === 11 ? cpfValidator.isValid(document) : cnpjValidator.isValid(document);
  },
};

export default documentValidator;
