import dayjs from 'dayjs';

export const minDate = {
  validate: (value: string) => dayjs(value).isAfter(dayjs()),
  message: (field: string, { min }: Record<string, any>) =>
    `O campo ${field} não pode ser inferior a ${dayjs(min).format('DD/MM/YYYY')}`,
  params: ['min'],
};

export const maxDate = {
  validate: (value: string) => dayjs(value).isBefore(dayjs()),
  message: (field: string, { max }: Record<string, any>) =>
    `O campo ${field} não pode ser superior a ${dayjs(max).format('DD/MM/YYYY')}`,
  params: ['max'],
};
