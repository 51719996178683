import Vue from 'vue';
import VueRouter, { NavigationGuard, RouteConfig } from 'vue-router';

import routeType from '../routes';
import useAuthStore from '../stores/auth';

Vue.use(VueRouter);

const auth: NavigationGuard = (to, from, next) => {
  if (useAuthStore().user) {
    next();
  } else {
    next(routeType.login);
  }
};

const authAndCompanySelected: NavigationGuard = (to, from, next) => {
  const authStore = useAuthStore();

  if (authStore.user && authStore.selected) {
    next();
  } else {
    next(routeType.baseCompany);
  }
};

const noAuth: NavigationGuard = (to, from, next) => {
  if (!useAuthStore().user) {
    next();
  } else {
    next(routeType.home);
  }
};

const routes: Array<RouteConfig> = [
  {
    path: routeType.login,
    component: () => import('../views/Login.vue'),
    beforeEnter: noAuth,
  },
  {
    path: routeType.baseCompany,
    component: () => import('../views/BaseCompany.vue'),
    meta: {
      title: 'Empresas',
    },
    beforeEnter: auth,
  },
  {
    path: routeType.home,
    component: () => import('../views/HomePage.vue'),
    redirect: routeType.portfolio,
    beforeEnter: authAndCompanySelected,
    children: [
      {
        path: routeType.provider,
        component: () => import('../views/Providers.vue'),
        meta: {
          title: 'Fornecedores',
        },
      },
      {
        path: routeType.providerRegister(':id?'),
        component: () => import('../views/ProviderRegister.vue'),
        meta: {
          title: 'Fornecedor',
          can: 'create Provider',
          fail: routeType.provider,
        },
      },
      {
        path: routeType.product,
        component: () => import('../views/Products.vue'),
        meta: {
          title: 'Produtos',
        },
      },
      {
        path: routeType.productRegister(':id?'),
        component: () => import('../views/ProductRegister.vue'),
        meta: {
          title: 'Produto',
          can: 'create Product',
          fail: routeType.product,
        },
        beforeEnter: auth,
      },
      {
        path: routeType.category,
        component: () => import('../views/Categories.vue'),
        meta: {
          title: 'Categorias',
        },
      },
      {
        path: routeType.categoryRegister(':id?'),
        component: () => import('../views/CategoryRegister.vue'),
        meta: {
          title: 'Categoria',
          can: 'create Category',
          fail: routeType.category,
        },
      },
      {
        path: routeType.business,
        component: () => import('../views/Businesses.vue'),
        meta: {
          title: 'Tipos de negócio',
        },
      },
      {
        path: routeType.businessRegister(':id?'),
        component: () => import('../views/BusinessRegister.vue'),
        meta: {
          title: 'Tipo de negócio',
          can: 'create Business',
          fail: routeType.business,
        },
      },
      {
        path: routeType.client,
        component: () => import('../views/Clients.vue'),
        meta: {
          title: 'Clientes',
        },
      },
      {
        path: routeType.clientView(':id'),
        component: () => import('../views/Client.vue'),
        meta: {
          title: 'Cliente',
        },
      },
      {
        path: routeType.clientRegister(':id?'),
        component: () => import('../views/ClientRegister.vue'),
        meta: {
          title: 'Cliente',
          can: 'create Client',
          fail: routeType.client,
        },
      },
      {
        path: routeType.reference,
        component: () => import('../views/References.vue'),
        meta: {
          title: 'Referências',
        },
      },
      {
        path: routeType.referenceView(':id'),
        component: () => import('../views/ReferenceView.vue'),
        meta: {
          title: 'Referência',
        },
      },
      {
        path: routeType.referenceRegister(':id?'),
        component: () => import('../views/ReferenceRegister.vue'),
        meta: {
          title: 'Referência',
          can: 'create Reference',
          fail: routeType.reference,
        },
      },
      {
        path: routeType.portfolio,
        redirect: routeType.portfolioMenu,
      },
      {
        path: routeType.portfolioMenu,
        component: () => import('../views/PortfolioMenu.vue'),
        meta: {
          title: 'Consultar Referências',
        },
      },
      {
        path: routeType.portfolioPresentation,
        component: () => import('../views/PortfolioPresentation.vue'),
        meta: {
          title: 'Referências',
        },
      },
      {
        path: routeType.portfolioShare(':id'),
        component: () => import('../views/SharePortfolio.vue'),
        meta: {
          title: 'Compartilhar Referências',
        },
      },
      {
        path: routeType.sharedPortfolios,
        component: () => import('../views/SharedPortfolios.vue'),
        meta: {
          title: 'Referências Compartilhadas',
        },
      },
      {
        path: routeType.sharedPortfolio(':id'),
        component: () => import('../views/SharedPortfolio.vue'),
        meta: {
          title: 'Referências',
        },
      },
      {
        path: routeType.log,
        component: () => import('../views/Logs.vue'),
        meta: {
          title: 'Auditoria',
        },
      },
      {
        path: routeType.video,
        component: () => import('../views/Videos.vue'),
        meta: {
          title: 'Vídeos',
        },
      },
      {
        path: routeType.videoRegister(':id?'),
        component: () => import('../views/VideoRegister.vue'),
        meta: {
          title: 'Vídeo',
          can: 'create Video',
          fail: routeType.video,
        },
      },
    ],
  },
  {
    path: routeType.publicSharedPortfolio(':id'),
    component: () => import('../views/PublicSharedPortfolio.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
});

export default router;
