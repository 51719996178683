import { APIDataResult } from '../models/api-data-result';
import Category from '../models/category';
import api from './api';
import apiRoutes from './api-routes';

const read = async (query?: any) => {
  const { data } = await api.get<APIDataResult<Category[]>>(apiRoutes.category, { params: query });

  return data.data.map((value) => Category.fromJSON(value));
};

const readOne = async (id: number) => {
  const { data } = await api.get<APIDataResult<Category>>(`${apiRoutes.category}/${id}`);

  return data.data && Category.fromJSON(data.data);
};

const create = async (category: Category) => {
  const { data } = await api.post<APIDataResult<Category>>(apiRoutes.category, category);

  return data.data && Category.fromJSON(data.data);
};

const update = async (category: Category) => {
  await api.put(`${apiRoutes.category}/${category.id}`, category);
};

const remove = async (category: Category) => {
  await api.delete(`${apiRoutes.category}/${category.id}`);
};

const categoryService = {
  read,
  readOne,
  create,
  update,
  remove,
};

export default categoryService;
