interface ProviderData {
  id?: number;
  name?: string;
  document?: string;
}

export default class Provider {
  id: number;

  name: string;

  document: string;

  constructor(params?: ProviderData) {
    Object.assign(this, params);
  }

  static fromJSON(data: ProviderData) {
    return new Provider(data);
  }
}
