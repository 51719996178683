import { defineStore } from 'pinia';

import Category from '../models/category';
import categoryService from '../services/category';
import useSnackbar from './snackbar';

const useCategoryStore = defineStore('category', {
  state: () => ({
    items: null as Category[],
    loading: false,
  }),
  actions: {
    async create(category: Category) {
      this.loading = true;

      try {
        const newCategory = await categoryService.create(category);

        this.items?.push(newCategory);

        return newCategory;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async load() {
      if (!this.items) {
        await this.read();
      }
    },
    async read() {
      this.items = [];

      this.loading = true;

      try {
        this.items = await categoryService.read({ paginate: false });
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async readOne(id: number) {
      const item = this.items?.find((category) => category.id === id);

      if (item) {
        return item;
      }

      this.loading = true;

      try {
        return await categoryService.readOne(id);
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async update(category: Category) {
      this.loading = true;

      const index = this.items?.findIndex(({ id }) => id === category.id);
      const isInList = this.items && index !== -1;
      const oldCategory = this.items[index];

      if (isInList) {
        this.items.splice(index, 1, category);
      }

      try {
        await categoryService.update(category);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        if (isInList) {
          this.items.splice(index, 1, oldCategory);
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
    async remove(category: Category) {
      const index = this.items.findIndex(({ id }) => id === category.id);
      const oldCategory = this.items[index];

      if (index === -1) {
        return false;
      }

      this.items.splice(index, 1);

      try {
        await categoryService.remove(category);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        this.items.splice(index, 0, oldCategory);

        return false;
      }
    },
  },
});

export default useCategoryStore;
