import axios from 'axios';
import { stringify } from 'qs';

import config from '../config';
import useAuthStore from '../stores/auth';

const api = axios.create({
  baseURL: config.apiURL,
  paramsSerializer: (params) =>
    stringify(params, {
      encode: false,
      arrayFormat: 'indices',
      skipNulls: true,
      strictNullHandling: true,
    }),
});

api.interceptors.request.use(async (request) => {
  const { user, selected } = useAuthStore();

  if (user && selected) {
    return {
      ...request,
      headers: {
        ...request.headers,
        authorization: user.token,
        'selected-company': selected.id,
      },
    };
  }

  return request;
});

api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error.response ? error.response.data : error),
);

export default api;
