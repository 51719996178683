import Category from './category';
import Contract from './contract';

export interface ReferenceData {
  id?: number;
  responsible?: string;
  phone?: string;
  email?: string;
  canContact?: boolean;
  categories?: Category[];
  recommendation?: string;
  video?: string;
  expiration?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  contract?: Contract;
  image?: string;
}

export default class Reference implements ReferenceData {
  id: number;

  responsible: string;

  phone: string;

  email: string;

  canContact: boolean;

  categories: Category[];

  recommendation: string;

  video: string;

  expiration: Date;

  createdAt: Date;

  updatedAt: Date;

  contract: Contract;

  image: string;

  constructor(params?: ReferenceData) {
    Object.assign(this, params);
  }

  static fromJSON(data: ReferenceData) {
    return new Reference({
      ...data,
      categories: data.categories && data.categories.map(Category.fromJSON),
      expiration: data.expiration && new Date(data.expiration),
      createdAt: data.createdAt && new Date(data.createdAt),
      updatedAt: data.updatedAt && new Date(data.updatedAt),
      contract: data.contract && Contract.fromJSON(data.contract),
    });
  }
}
