import { defineStore } from 'pinia';

type SnackbarStatus = 'info' | 'success' | 'warning' | 'error';

const useSnackbar = defineStore('snackbar', {
  state: () => ({
    visible: false,
    messages: [] as string[],
    status: 'info' as SnackbarStatus,
  }),
  actions: {
    show(text: string | string[], status: SnackbarStatus = 'info') {
      this.visible = true;
      this.messages = ([] as string[]).concat(text);
      this.status = status;
    },
    close() {
      this.$reset();
    },
  },
});

export default useSnackbar;
