import Vue from 'vue';
import Acl, { Options, SetupCallback } from 'vue-browser-acl';

import { Actions, Functionalities } from '../models/app-ability';
import User from '../models/user';
import { matchRoles, Roles } from '../roles';
import router from '../router';
import useAuthStore from '../stores/auth';

const currentUser = () => useAuthStore().user;

const setup: SetupCallback = (acl) => {
  const can = (actions: Actions[], functionalities: Functionalities[], test: (user: User) => boolean) => {
    functionalities.map((subject) => acl.rule(actions, subject, test));
  };

  can(
    ['create', 'edit', 'remove', 'read'],
    ['Business', 'Category', 'Client', 'Product', 'Provider', 'Reference', 'Contract', 'Audit', 'Video'],
    (user: User) => matchRoles([Roles.Admin], user.roles),
  );

  can(['read'], ['Portfolio', 'Video'], (user: User) => matchRoles([Roles.Admin, Roles.Seller], user.roles));
};

const aclOptions: Partial<Options> = {
  router,
  failRoute: '$from',
};

Vue.use(Acl, currentUser, setup, aclOptions);
