const apiRoutes = {
  address: '/addresses',
  login: '/auth/login',
  company: '/company',
  provider: '/providers',
  product: '/products',
  category: '/categories',
  business: '/businesses',
  client: '/clients',
  reference: '/references',
  contract: '/contracts',
  portfolio: '/portfolio',
  sharedPortfolio: '/shared-portfolio',
  log: '/logs',
  video: '/videos',
};

export default apiRoutes;
