import { APIDataResult } from '../models/api-data-result';
import Product from '../models/product';
import api from './api';
import apiRoutes from './api-routes';

const read = async (query?: any) => {
  const { data } = await api.get<APIDataResult<Product[]>>(apiRoutes.product, { params: query });

  return data.data.map((value) => Product.fromJSON(value));
};

const readOne = async (id: number) => {
  const { data } = await api.get<APIDataResult<Product>>(`${apiRoutes.product}/${id}`);

  return data.data && Product.fromJSON(data.data);
};

const create = async (product: Product) => {
  const { data } = await api.post<APIDataResult<Product>>(apiRoutes.product, {
    ...product,
    provider: undefined,
    providerId: product.provider.id,
  });

  return data.data && Product.fromJSON({ provider: product.provider, ...data.data });
};

const update = async (product: Product) => {
  await api.put(`${apiRoutes.product}/${product.id}`, {
    ...product,
    source: undefined,
    provider: undefined,
    providerId: product.provider.id,
  });
};

const remove = async (product: Product) => {
  await api.delete(`${apiRoutes.product}/${product.id}`);
};

const productService = {
  read,
  readOne,
  create,
  update,
  remove,
};

export default productService;
