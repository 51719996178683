import Query from './query';
import Business from '@/models/business';
import Category from '@/models/category';
import Product from '@/models/product';

export default class PortfolioFilter {
  product: Product;

  state: string;

  city: string;

  categories: Category[];

  business: Business;

  constructor(params?: Partial<PortfolioFilter>) {
    Object.assign(this, params);
  }

  toQuery(): Query {
    return {
      filter: {
        product: this.product.id,
        state: this.state,
        city: this.city,
        business: this.business?.id,
        categories: this.categories?.map((category) => category.id),
      },
    };
  }
}
