import { APIDataResult } from '../models/api-data-result';
import Business from '../models/business';
import api from './api';
import apiRoutes from './api-routes';

const read = async (query?: any) => {
  const { data } = await api.get<APIDataResult<Business[]>>(apiRoutes.business, { params: query });

  return data.data.map((value) => Business.fromJSON(value));
};

const readOne = async (id: number) => {
  const { data } = await api.get<APIDataResult<Business>>(`${apiRoutes.business}/${id}`);

  return data.data && Business.fromJSON(data.data);
};

const create = async (business: Business) => {
  const { data } = await api.post<APIDataResult<Business>>(apiRoutes.business, business);

  return data.data && Business.fromJSON(data.data);
};

const update = async (business: Business) => {
  await api.put(`${apiRoutes.business}/${business.id}`, business);
};

const remove = async (business: Business) => {
  await api.delete(`${apiRoutes.business}/${business.id}`);
};

const businessService = {
  read,
  readOne,
  create,
  update,
  remove,
};

export default businessService;
