import { defineStore } from 'pinia';

import Product from '../models/product';
import productService from '../services/product';
import useSnackbar from './snackbar';

const useProductStore = defineStore('product', {
  state: () => ({
    items: null as Product[],
    loading: false,
  }),
  actions: {
    async create(product: Product) {
      this.loading = true;

      try {
        const newProduct = await productService.create(product);

        this.items?.push(newProduct);

        return newProduct;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async load() {
      if (!this.items) {
        await this.read();
      }
    },
    async read() {
      this.items = [];

      this.loading = true;

      try {
        this.items = await productService.read({
          join: ['provider'],
          fields: ['product.*', 'provider.*'],
          paginate: false,
        });
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async readOne(id: number) {
      const item = this.items?.find((product) => product.id === id);

      if (item) {
        return item;
      }

      this.loading = true;

      try {
        return await productService.readOne(id);
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async update(product: Product) {
      this.loading = true;

      const index = this.items?.findIndex(({ id }) => id === product.id);
      const isInList = this.items && index !== -1;
      const oldProduct = this.items[index];

      if (isInList) {
        this.items.splice(index, 1, product);
      }

      try {
        await productService.update(product);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        if (isInList) {
          this.items.splice(index, 1, oldProduct);
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
    async remove(product: Product) {
      const index = this.items.findIndex(({ id }) => id === product.id);
      const oldProduct = this.items[index];

      if (index === -1) {
        return false;
      }

      this.items.splice(index, 1);

      try {
        await productService.remove(product);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        this.items.splice(index, 0, oldProduct);

        return false;
      }
    },
  },
});

export default useProductStore;
