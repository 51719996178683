import { defineStore } from 'pinia';

import { SharedPortfolio } from '../models/shared-portfolio';
import portfolioService from '../services/portfolio';
import useSnackbar from './snackbar';

const useSharedPortfolioStore = defineStore('sharedPortfolio', {
  state: () => ({
    items: null as SharedPortfolio[],
    item: null as SharedPortfolio,
    loading: false,
  }),
  actions: {
    async readOne(id: string, isPublic = false): Promise<SharedPortfolio> {
      if (this.item?.id === id) {
        return this.item;
      }

      this.loading = true;

      try {
        this.item = await portfolioService[isPublic ? 'readOnePublic' : 'readOne'](id);
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        this.item = null;
      } finally {
        this.loading = false;
      }

      return this.item;
    },
    async readOnePublic(id: string): Promise<SharedPortfolio> {
      return this.readOne(id, true);
    },
    async read(): Promise<SharedPortfolio[]> {
      this.items = [];

      this.loading = true;

      try {
        this.items = await portfolioService.read({ paginate: false });
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async load() {
      if (!this.items) {
        await this.read();
      }
    },
    async setItem(item: SharedPortfolio) {
      this.item = item;
    },
  },
});

export default useSharedPortfolioStore;
