import VueCompositionAPI from '@vue/composition-api';
import Vue from 'vue';

import './assets/styles.scss';
import './plugins/ability';
import './registerServiceWorker';
import App from './App.vue';
import pinia from './plugins/pinia';
import vuetify from './plugins/vuetify';
import router from './router';

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);

new Vue({
  router,
  pinia,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
