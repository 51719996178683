import Address from './address';
import Business from './business';

export interface ClientData {
  id?: number;
  code?: string;
  name?: string;
  fantasy?: string;
  document?: string;
  responsible?: string;
  phone?: string;
  email?: string;
  business?: Business;
  address?: Address;
}

export default class Client implements ClientData {
  id: number;

  code: string;

  name: string;

  fantasy: string;

  document: string;

  responsible: string;

  phone: string;

  email: string;

  business: Business;

  address: Address;

  constructor(params?: ClientData) {
    Object.assign(this, params);
  }

  static fromJSON(data: ClientData) {
    return new Client({
      ...data,
      business: data.business && Business.fromJSON(data.business),
      address: data.address && Address.fromJSON(data.address),
    });
  }
}
