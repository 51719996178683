import { defineStore } from 'pinia';

import Contract from '../models/contract';
import PortfolioFilter from '../models/portfolio-filter';
import PortfolioGroup from '../models/portfolio-group';
import portfolioService from '../services/portfolio';
import useSharedPortfolioStore from './shared-portfolio';
import useSnackbar from './snackbar';

const groupPortfolio = (
  groups: {
    key: string;
    icon: string;
    title: string;
    hide?: boolean;
    filter: (value: Contract) => boolean;
  }[],
  items: Contract[],
) =>
  groups.reduce<PortfolioGroup[]>((buffer, group) => {
    if (group.hide) {
      return buffer;
    }

    const groupItems = items.filter(group.filter);

    if (groupItems.length) {
      buffer.push({
        icon: group.icon,
        items: groupItems,
        key: group.key,
        title: group.title,
      });
    }

    return buffer;
  }, []);

const usePortfolioStore = defineStore('portfolio', {
  state: () => ({
    items: [] as Contract[],
    filters: new PortfolioFilter(),
    loading: false,
    selected: [] as Contract[],
    shareSelectionLimit: 3 as const,
  }),
  actions: {
    async read(filter: PortfolioFilter) {
      this.filters = filter;
      this.items = [];

      try {
        this.loading = true;
        this.items = await portfolioService.findContracts(filter.toQuery());
        this.selectContractsToShare();
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async share() {
      this.loading = true;

      const contractIds = this.selected.map(({ id }) => id);

      try {
        const shared = await portfolioService.share({
          contractIds,
        });

        useSharedPortfolioStore().setItem(shared);

        return shared;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    selectContractsToShare() {
      this.selected = [...this.items]?.sort(({ reference }) => (reference?.canContact ? -1 : 1)).slice(0, 3);
    },
  },
  getters: {
    groupedItems: (state) =>
      groupPortfolio(
        [
          {
            key: 'city',
            filter: ({ reference, client }) => reference && client.address.city === state.filters.city,
            hide: !state.filters.city,
            title: 'Cidade',
            icon: 'mdi-home-city-outline',
          },
          {
            key: 'state',
            filter: ({ reference, client }) =>
              reference && client.address.city !== state.filters.city && client.address.state === state.filters.state,
            hide: !state.filters.state,
            title: 'Estado',
            icon: 'mdi-map-search-outline',
          },
          {
            key: 'general',
            filter: ({ reference, client }) =>
              reference && client.address.city !== state.filters.city && client.address.state !== state.filters.state,
            hide: Boolean(state.filters.city || state.filters.state),
            title: 'Referência',
            icon: 'mdi-account-box-multiple-outline',
          },
          {
            key: 'featured',
            title: 'Destaque',
            icon: 'mdi-star-outline',
            filter: ({ reference }) => reference?.categories.some(({ featured }) => featured),
          },
          {
            key: 'unrelated',
            filter: ({ reference }) => !reference,
            title: 'Sem referência',
            icon: 'mdi-account-multiple-outline',
          },
        ],
        state.items,
      ),
    isShareSelectionLimitReached: (state) => state.selected.length === state.shareSelectionLimit,
  },
});

export default usePortfolioStore;
