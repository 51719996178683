import { defineStore } from 'pinia';

import Business from '../models/business';
import businessService from '../services/business';
import useSnackbar from './snackbar';

const useBusinessStore = defineStore('business', {
  state: () => ({
    items: null as Business[],
    loading: false,
  }),
  actions: {
    async create(business: Business) {
      this.loading = true;

      try {
        const newBusiness = await businessService.create(business);

        this.items?.push(newBusiness);

        return newBusiness;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async load() {
      if (!this.items) {
        await this.read();
      }
    },
    async read() {
      this.items = [];

      this.loading = true;

      try {
        this.items = await businessService.read({ paginate: false });
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async readOne(id: number) {
      const item = this.items?.find((business) => business.id === id);

      if (item) {
        return item;
      }

      this.loading = true;

      try {
        return await businessService.readOne(id);
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async update(business: Business) {
      this.loading = true;

      const index = this.items?.findIndex(({ id }) => id === business.id);
      const isInList = this.items && index !== -1;
      const oldBusiness = this.items[index];

      if (isInList) {
        this.items.splice(index, 1, business);
      }

      try {
        await businessService.update(business);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        if (isInList) {
          this.items.splice(index, 1, oldBusiness);
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
    async remove(business: Business) {
      const index = this.items.findIndex(({ id }) => id === business.id);
      const oldBusiness = this.items[index];

      if (index === -1) {
        return false;
      }

      this.items.splice(index, 1);

      try {
        await businessService.remove(business);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        this.items.splice(index, 0, oldBusiness);

        return false;
      }
    },
  },
});

export default useBusinessStore;
