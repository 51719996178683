interface AddressData {
  id?: number;
  postalCode?: string;
  city?: string;
  state?: string;
  neighborhood?: string;
  street?: string;
  number?: string;
  latitude?: number;
  longitude?: number;
}

export default class Address implements AddressData {
  id: number;

  postalCode: string;

  city: string;

  state: string;

  neighborhood: string;

  street: string;

  number: string;

  latitude: number;

  longitude: number;

  get ui() {
    const street = this.street ? `${this.street},` : '';
    const number = this.number ? `${this.number},` : '';
    const neighborhood = this.neighborhood ? `${this.neighborhood},` : '';

    return `${street} ${number} ${neighborhood} ${this.city} - ${this.state}`;
  }

  constructor(params?: AddressData) {
    Object.assign(this, params);
  }

  static fromJSON(data: AddressData) {
    return new Address(data);
  }
}
