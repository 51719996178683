import { defineStore } from 'pinia';

import Contract from '../models/contract';
import contractService from '../services/contract';
import useSnackbar from './snackbar';

const useContractStore = defineStore('contract', {
  state: () => ({
    items: null as Contract[],
    loading: false,
    clientId: 0,
  }),
  actions: {
    async create(contract: Contract) {
      this.loading = true;

      try {
        const newContract = await contractService.create(contract);

        this.items?.push(newContract);

        return newContract;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async load(clientId: number) {
      if (this.clientId !== clientId || !this.items) {
        this.clientId = clientId;

        await this.read();
      }
    },
    async read() {
      this.items = [];

      if (!this.clientId) {
        return this.items;
      }

      this.loading = true;

      try {
        this.items = await contractService.read({
          join: ['product', 'client'],
          fields: ['contract.*', 'product.id', 'product.name', 'client.name'],
          filter: { clientId: { _eq: this.clientId } },
          paginate: false,
        });
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async update(contract: Contract) {
      this.loading = true;

      const index = this.items?.findIndex(({ id }) => id === contract.id);
      const isInList = this.items && index !== -1;
      const oldContract = this.items[index];

      if (isInList) {
        this.items.splice(index, 1, contract);
      }

      try {
        await contractService.update(contract);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        if (isInList) {
          this.items.splice(index, 1, oldContract);
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
  },
});

export default useContractStore;
