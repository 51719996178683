import dayjs from 'dayjs';

import Client from './client';
import Product from './product';
import Reference from './reference';

export interface ContractData {
  id?: number;
  since?: Date;
  cancellation?: Date;
  product?: Product;
  client?: Client;
  createdAt?: Date;
  reference?: Reference;
}

export default class Contract implements ContractData {
  id: number;

  since: Date;

  cancellation: Date;

  product: Product;

  client: Client;

  createdAt: Date;

  reference: Reference;

  constructor(params?: ContractData) {
    Object.assign(this, params);
  }

  static fromJSON(data: ContractData): Contract {
    return new Contract({
      ...data,
      since: data.since && dayjs(data.since).toDate(),
      cancellation: data.cancellation && dayjs(data.cancellation).toDate(),
      product: data.product && Product.fromJSON(data.product),
      client: data.client && Client.fromJSON(data.client),
      createdAt: data.createdAt && new Date(data.createdAt),
      reference: data.reference && Reference.fromJSON(data.reference),
    });
  }
}
