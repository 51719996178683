import { APIDataResult } from '../models/api-data-result';
import Provider from '../models/provider';
import api from './api';
import apiRoutes from './api-routes';

const read = async (query?: any) => {
  const { data } = await api.get<APIDataResult<Provider[]>>(apiRoutes.provider, { params: query });

  return data.data.map((value) => Provider.fromJSON(value));
};

const readOne = async (id: number) => {
  const { data } = await api.get(`${apiRoutes.provider}/${id}`);

  return data.data && Provider.fromJSON(data.data);
};

const create = async (provider: Provider) => {
  const { data } = await api.post(apiRoutes.provider, provider);

  return data.data && Provider.fromJSON(data.data);
};

const update = async (provider: Provider) => {
  await api.put(`${apiRoutes.provider}/${provider.id}`, provider);
};

const remove = async (provider: Provider) => {
  await api.delete(`${apiRoutes.provider}/${provider.id}`);
};

const providerService = {
  read,
  readOne,
  create,
  update,
  remove,
};

export default providerService;
