import Account from '../models/account';
import { APIDataResult } from '../models/api-data-result';
import LoginInfo from '../models/login-info';
import api from './api';
import apiRoutes from './api-routes';

const login = async (account: Account) => {
  const { data } = await api.post<APIDataResult<LoginInfo>>(apiRoutes.login, account);

  return data.data;
};

const authService = {
  login,
};

export default authService;
