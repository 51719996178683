import { defineStore } from 'pinia';

import Provider from '../models/provider';
import providerService from '../services/provider';
import useSnackbar from './snackbar';

const useProviderStore = defineStore('provider', {
  state: () => ({
    items: null as Provider[],
    loading: false,
  }),
  actions: {
    async create(provider: Provider) {
      this.loading = true;

      try {
        const newProvider = await providerService.create(provider);

        this.items.push(newProvider);

        return newProvider;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async load() {
      if (!this.items) {
        await this.read();
      }
    },
    async read() {
      this.items = [];

      this.loading = true;

      try {
        this.items = await providerService.read({ paginate: false });
      } catch (error) {
        useSnackbar().show(error.message, 'error');
      } finally {
        this.loading = false;
      }

      return this.items;
    },
    async readOne(id: number) {
      const item = this.items.find((provider) => provider.id === id);

      if (item) {
        return item;
      }

      this.loading = true;

      try {
        return await providerService.readOne(id);
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        return null;
      } finally {
        this.loading = false;
      }
    },
    async update(provider: Provider) {
      this.loading = true;

      const index = this.items.findIndex(({ id }) => id === provider.id);
      const isInList = index !== -1;
      const oldProvider = this.items[index];

      if (isInList) {
        this.items.splice(index, 1, provider);
      }

      try {
        await providerService.update(provider);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        if (isInList) {
          this.items.splice(index, 1, oldProvider);
        }

        return false;
      } finally {
        this.loading = false;
      }
    },
    async remove(provider: Provider) {
      const index = this.items.findIndex(({ id }) => id === provider.id);
      const oldProvider = this.items[index];

      if (index === -1) {
        return false;
      }

      this.items.splice(index, 1);

      try {
        await providerService.remove(provider);

        return true;
      } catch (error) {
        useSnackbar().show(error.message, 'error');

        this.items.splice(index, 0, oldProvider);

        return false;
      }
    },
  },
});

export default useProviderStore;
