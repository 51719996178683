const routes = {
  home: '/',
  login: '/login',
  baseCompany: '/base-company',
  log: '/log',
  provider: '/provider',
  providerRegister: (id?: any) => `/provider/register/${id || ''}`,
  product: '/product',
  productRegister: (id?: any) => `/product/register/${id || ''}`,
  category: '/category',
  categoryRegister: (id?: any) => `/category/register/${id || ''}`,
  business: '/business',
  businessRegister: (id?: any) => `/business/register/${id || ''}`,
  client: '/client',
  clientView: (id: any) => `/client/view/${id}`,
  clientRegister: (id?: any) => `/client/register/${id || ''}`,
  reference: '/reference',
  referenceView: (id: any) => `/reference/view/${id}`,
  referenceRegister: (id?: any) => `/reference/register/${id || ''}`,
  video: '/video',
  videoRegister: (id?: any) => `/video/register/${id || ''}`,
  portfolio: '/portfolio',
  portfolioMenu: '/portfolio/search',
  portfolioPresentation: '/portfolio/presentation',
  sharedPortfolios: '/portfolio/shared/',
  portfolioShare: (id: string) => `/portfolio/${id}/share`,
  sharedPortfolio: (id: string) => `/portfolio/shared/${id}`,
  publicSharedPortfolio: (id: string) => `/public/portfolio/${id}`,
};

export default routes;
