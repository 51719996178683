interface BusinessData {
  id?: number;
  name?: string;
}

export default class Business {
  id: number;

  name: string;

  constructor(params?: BusinessData) {
    Object.assign(this, params);
  }

  static fromJSON(data: BusinessData) {
    return new Business(data);
  }
}
