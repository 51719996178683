import dayjs from 'dayjs';

import Address from './address';
import Category from './category';
import CreatedBy from './created-by';

export interface SharedClient {
  id: number;

  document: string;

  name: string;

  address: Address;
}

export interface SharedProduct {
  id: number;

  name: string;
}

export class SharedReference {
  id: number;

  recommendation: string;

  responsible: string;

  phone: string;

  email: string;

  video: string;

  categories: Category[];

  constructor(params?: Partial<SharedReference>) {
    Object.assign(this, params);
  }

  static fromJSON(data: any) {
    return new SharedReference({
      ...data,
      categories: data.categories && data.categories.map((name: string) => new Category({ name })),
    });
  }
}

export class SharedContract {
  id: string;

  contractId: number;

  since: Date;

  client: SharedClient;

  product: SharedProduct;

  reference: SharedReference;

  constructor(params?: Partial<SharedContract>) {
    Object.assign(this, params);
  }

  static fromJSON(data: Partial<SharedContract>) {
    return new SharedContract({
      ...data,
      since: data.since && dayjs(data.since).toDate(),
      reference: data.reference && SharedReference.fromJSON(data.reference),
    });
  }
}

export class SharedPortfolio {
  id: string;

  expiration: Date;

  createdAt: Date;

  updatedAt: Date;

  createdBy: CreatedBy;

  contracts: SharedContract[];

  get publicLink() {
    return `${window.location.host}/public/portfolio/${this.id}`;
  }

  constructor(params?: Partial<SharedPortfolio>) {
    Object.assign(this, params);
  }

  static fromJSON(data: Partial<SharedPortfolio>) {
    return new SharedPortfolio({
      ...data,
      expiration: data.expiration && dayjs(data.expiration).toDate(),
      createdAt: data.createdAt && new Date(data.createdAt),
      updatedAt: data.updatedAt && new Date(data.updatedAt),
      contracts: data.contracts && data.contracts.map(SharedContract.fromJSON),
    });
  }
}
